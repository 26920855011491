<template>
  <v-card tile flat color="transparent">
    <v-rating v-model="rating" clearable :class="ratingClass">
      <template v-slot:item="props">
        <v-hover v-slot="{ hover }" open-delay="200">
          <v-icon
            :color="
              rating === props.index + 1 || hover
                ? colors[props.index]
                : 'grey lighten-1'
            "
            x-large
            :size="hover ? 35 : 25"
            @click="props.click"
          >
            {{
              rating === props.index + 1 || hover
                ? emojis[props.index]
                : `${emojis[props.index]}-outline`
            }}
          </v-icon>
        </v-hover>
      </template>
    </v-rating>

    <v-scroll-y-transition>
      <v-card
        v-if="rating > 0 && !hideText"
        tile
        flat
        color="transparent"
        class="d-flex justify-center text-h6 font-weight-medium mt-1 secondary--text text--lighten-2"
      >
        {{ selectedReaction[rating] }}
      </v-card>
    </v-scroll-y-transition>
  </v-card>
</template>
<script>
export default {
  name: "FeelingRatingPicker",

  props: {
    hideText: { type: Boolean, default: false },
    ratingClass: { type: String, default: "" },
    selected: { type: [String, Number], default: 0 },
  },

  data: () => ({
    colors: [
      "red darken-3",
      "red",
      "orange",
      "success lighten-1",
      "success darken-2",
    ],
    rating: 0,
    emojis: [
      "mdi-emoticon-angry",
      "mdi-emoticon-sad",
      "mdi-emoticon-neutral",
      "mdi-emoticon-happy",
      "mdi-emoticon-excited",
    ],
    message: "",
    onboarding: 1,
    image: require("@/assets/images/feedback_check.png"),
  }),
  computed: {
    selectedReaction() {
      return [
        this.$t("feedbackRating.noRating"),
        this.$t("feedbackRating.veryDissatisfied"),
        this.$t("feedbackRating.dissatisfied"),
        this.$t("feedbackRating.indifferent"),
        this.$t("feedbackRating.satisfied"),
        this.$t("feedbackRating.verySatisfied"),
      ];
    },
  },
  watch: {
    rating() {
      this.$emit("update", this.rating);
    },

    selected() {
      this.rating = this.selected;
    },
  },

  beforeMount() {
    this.rating = this.selected;
  },
};
</script>
